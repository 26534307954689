@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;1,400;1,700&display=swap");
:root {
  --bs-nb: #1B2D3E;
  --bs-c: #2286C7;
  --bs-nbm: #2E4F6F;
  --bs-g: #00A61A;
  --bs-glght: #D8FFDE;
  --bs-go: #82D38F;
  --bs-rm: #E06645;
  --bs-y: #FF9700;
  --bs-gm: #535353;
  --bs-gl: #8B8B8B;
  --bs-o: #E5E5E5;
  --bs-lo: #E8E8E8;
  --bs-b: #000;
  --bs-w: #fff;
}

body {
  background-color: var(--bs-w);
  color: var(--bs-gm);
  font-family: "Open Sans", sans-serif;
  font-size: 0.875rem;
  line-height: 1.5714285714;
}

a {
  color: var(--bs-b);
  font-weight: 400;
  text-decoration: none;
}
a:hover, a:focus {
  text-decoration: underline;
}

button[role=link] {
  appearance: none;
  background-color: transparent;
  border: none;
  color: var(--bs-g);
  cursor: pointer;
  padding: 0;
}
button[role=link]:hover, button[role=link]:focus {
  text-decoration: underline;
}

strong {
  font-weight: 700;
}

:is(h1, h2, h3) {
  color: var(--bs-b);
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0;
  margin-block-start: 0;
  margin-block-end: 1.875rem;
}

:is(.strong) {
  font-weight: 700;
}

h1, .h1 {
  font-size: 2rem;
  line-height: 1.375;
}
@media screen and (min-width: 64rem) {
  h1, .h1 {
    font-size: 3.25rem;
    line-height: 1.3653846154;
  }
}

h2, .h2 {
  font-size: 1.5rem;
  line-height: 1.375;
}
@media screen and (min-width: 64rem) {
  h2, .h2 {
    font-size: 2.625rem;
    line-height: 1.3571428571;
  }
}

h3, .h3 {
  font-size: 1.125rem;
  line-height: 1.3888888889;
}
@media screen and (min-width: 64rem) {
  h3, .h3 {
    font-size: 1.5rem;
    line-height: 1.375;
  }
}

.p-big {
  font-size: 1.125rem;
  line-height: 1.3888888889;
}
@media screen and (min-width: 64rem) {
  .p-big {
    font-size: 1.375rem;
    line-height: 1.3636363636;
  }
}

.p-small {
  font-size: 0.75rem;
  line-height: 1.3333333333;
}

@media screen and (min-width: 64rem) {
  .bs-website {
    display: grid;
    grid-template-rows: auto 1fr auto;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.bs-w-mobile-nav {
  display: flex;
  justify-content: center;
  margin-block-end: 1.875rem;
}
.bs-w-mobile-nav a {
  border-block-end: 0 solid var(--bs-g);
  color: var(--bs-gm);
  padding-block-end: 0.625rem;
  transition: font-weight 0.2s linear, border-width 0.2s linear;
}
.bs-w-mobile-nav a:hover, .bs-w-mobile-nav a:focus, .bs-w-mobile-nav a.router-link-exact-active {
  border-block-end-width: 0.0625rem;
  color: var(--bs-b);
  font-weight: 700;
  text-decoration: none;
}
.bs-w-mobile-nav a + a {
  margin-inline-start: 2.5rem;
}

.bs-w-article-content {
  margin-inline: auto;
  max-inline-size: 100%;
  padding-block: 5rem;
  padding-inline: 1.875rem;
}
@media screen and (min-width: 64rem) {
  .bs-w-article-content {
    max-inline-size: 81.25rem;
    padding-block: 10rem;
  }
}

.bs-w-member-ellipse {
  align-items: center;
  block-size: 35rem;
  display: flex;
  justify-content: center;
  position: relative;
}
.bs-w-member-ellipse .bs-w-m-e-one,
.bs-w-member-ellipse .bs-w-m-e-two,
.bs-w-member-ellipse .bs-w-m-e-three,
.bs-w-member-ellipse .bs-w-m-e-four {
  border: 0.0625rem solid #ECECEC;
  box-sizing: border-box;
  border-radius: 50%;
  position: relative;
}
.bs-w-member-ellipse [class^=bs-w-m-e-] > img {
  border-radius: 50%;
  position: absolute;
  z-index: 1;
}
.bs-w-member-ellipse [class^=bs-w-m-e-] > img.bs-w-m-e-avatar-1 {
  right: 8.9375rem;
  top: 2.9375rem;
}
.bs-w-member-ellipse [class^=bs-w-m-e-] > img.bs-w-m-e-avatar-2 {
  left: 8rem;
  top: 4.1875rem;
}
.bs-w-member-ellipse [class^=bs-w-m-e-] > img.bs-w-m-e-avatar-3 {
  left: 3.1875rem;
  top: 12.0625rem;
}
.bs-w-member-ellipse [class^=bs-w-m-e-] > img.bs-w-m-e-avatar-4 {
  right: 7.1875rem;
  top: 13.5rem;
}
.bs-w-member-ellipse [class^=bs-w-m-e-] > img.bs-w-m-e-avatar-6 {
  bottom: 13.75rem;
  left: 7.0625rem;
}
.bs-w-member-ellipse [class^=bs-w-m-e-] > img.bs-w-m-e-avatar-7 {
  bottom: 9.3125rem;
  left: 0;
}
.bs-w-member-ellipse [class^=bs-w-m-e-] > img.bs-w-m-e-avatar-10 {
  bottom: 11.8125rem;
  right: 3.3125rem;
}
.bs-w-member-ellipse [class^=bs-w-m-e-] > img.bs-w-m-e-avatar-11 {
  bottom: 7.875rem;
  right: 12.3125rem;
}
.bs-w-member-ellipse [class^=bs-w-m-e-] > img.bs-w-m-e-avatar-12 {
  bottom: 4.125rem;
  left: 9.5rem;
}
.bs-w-member-ellipse [class^=bs-w-m-e-] > img.bs-w-m-e-avatar-13 {
  bottom: 2.25rem;
  right: 5rem;
}
.bs-w-member-ellipse .bs-w-m-e-one {
  block-size: 35rem;
  inline-size: 35rem;
}
.bs-w-member-ellipse .bs-w-m-e-two {
  block-size: 26.375rem;
  inline-size: 26.375rem;
  left: calc(50% - 13.1875rem);
  top: calc(50% - 13.1875rem);
}
.bs-w-member-ellipse .bs-w-m-e-three {
  block-size: 17.625rem;
  inline-size: 17.625rem;
  left: calc(50% - 8.8125rem);
  top: calc(50% - 8.8125rem);
}
.bs-w-member-ellipse .bs-w-m-e-four {
  align-items: center;
  block-size: 10.5rem;
  display: flex;
  inline-size: 10.5rem;
  justify-content: center;
  top: calc(50% - 5.25rem);
  left: calc(50% - 5.25rem);
}
.bs-w-member-ellipse-small {
  block-size: 11.875rem;
}
.bs-w-member-ellipse-small .bs-w-m-e-one {
  block-size: 11.875rem;
  inline-size: 11.875rem;
}
.bs-w-member-ellipse-small .bs-w-m-e-two {
  block-size: 9.125rem;
  inline-size: 9.125rem;
  left: calc(50% - 4.5625rem);
  top: calc(50% - 4.5625rem);
}
.bs-w-member-ellipse-small img:not(.q-icon) {
  left: calc(50% - 3.0625rem);
  top: calc(50% - 3.0625rem);
}

.bs-w-chip {
  border-radius: 0.375rem;
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1.3333333333;
  padding-block: 0.375rem;
  padding-inline: 0.875rem;
}

.bs-modal .q-dialog__inner > * {
  background-color: #fff;
  box-shadow: 0.25rem 0.25rem 0.375rem rgba(0, 0, 0, 0.05);
  padding: 3.125rem;
  position: relative;
}
.bs-modal__close {
  color: #00A61A;
  cursor: pointer;
  font-size: 0.9375rem;
  position: absolute;
  right: 1.875rem;
  top: 1.25rem;
}
.bs-modal__close .q-btn__wrapper {
  min-block-size: 1.125rem;
  min-inline-size: 1.125rem;
  padding: 0;
}

@keyframes flip {
  from {
    transform: rotateY(180deg);
  }
  to {
    transform: rotateY(-180deg);
  }
}
.bs-spinner {
  align-items: center;
  background-color: var(--bs-g);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  padding: 1.25rem;
}
.bs-spinner svg {
  block-size: 3rem;
  inline-size: 3rem;
  color: var(--bs-w);
}
.bs-spinner svg path {
  animation: flip 3s linear infinite;
  transform-origin: 50% 50%;
}