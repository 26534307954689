@use "sass:map"
@use '~booksprout-app/src/css/functions/rem-fn' as *
@use '~booksprout-app/src/css/functions/line-height-fn' as *
@use '~booksprout-app/src/css/mixins/media' as media-query
@use '../variables/typography'
@use '../variables/media'

@mixin generate-paragraphs()
  .p-big
    font-size: rem-fn(map.get(typography.$p-big-mobile, 'font-size'))
    line-height: line-height(map.get(typography.$p-big-mobile, 'line-height'), map.get(typography.$p-big-mobile, 'font-size'))

    @include media-query.from(media.$md-min)
      font-size: rem-fn(map.get(typography.$p-big, 'font-size'))
      line-height: line-height(map.get(typography.$p-big, 'line-height'), map.get(typography.$p-big, 'font-size'))

  .p-small
    font-size: rem-fn(map.get(typography.$p-small, 'font-size'))
    line-height: line-height(map.get(typography.$p-small, 'line-height'), map.get(typography.$p-small, 'font-size'))
