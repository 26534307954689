// Quasar Sass (& SCSS) Variables
// --------------------------------------------------
// To customize the look and feel of this app, you can override
// the Sass/SCSS variables found in Quasar's source Sass/SCSS files.

// Check documentation for full list of Quasar variables

// Your own variables (that are declared here) and Quasar's own
// ones will be available out of the box in your .vue/.scss/.sass files

// It's highly recommended to change the default colors
// to match your app's branding.
// Tip: Use the "Theme Builder" on Quasar's documentation website.

@import './bs-design.variables.sass'

$primary   : $bs-navy-blue
$secondary : $bs-green
$accent    : $bs-light-blue-medium
$s-accent: rgba(0, 166, 26, 0.05)

$positive  : #21BA45
$negative  : $bs-red
$info      : #31CCEC
$warning   : #F2C037

// these are required temporary for website to work
// remove once upgraded to "@quasar/app" v3
$space-base     : 16px
$flex-gutter-sm : ($space-base * .5)
$flex-gutter-md : $space-base
$flex-gutter-xl : ($space-base * 3)

$breakpoint-xs: 599px
$breakpoint-sm: 1023px
$breakpoint-md: 1439px
$breakpoint-lg: 1919px

$sizes: ('xs': 0, 'sm': ($breakpoint-xs + 1), 'md': ($breakpoint-sm + 1), 'lg': ($breakpoint-md + 1), 'xl': ($breakpoint-lg + 1))

$breakpoint-sm-min: map-get($sizes, "sm")
$breakpoint-md-min: map-get($sizes, "md")

$grey-5 : #bdbdbd

// general overrides
$generic-border-radius          : 6px // $bs-border-radius-sm
$typography-font-family         : 'Open Sans', sans-serif

// button overrides
$button-border-radius           : 0.375rem // $bs-border-radius-sm
$button-padding                 : 0.435rem .875rem
$button-font-size               : .75rem
$button-line-height             : 1.25
$button-font-weight             : 700
$button-shadow                  : none
$button-shadow-active           : none
$button-rounded-border-radius   : 50%

// input overrides
$input-font-size                : .9375rem
$input-text-color               : #000

// toolbar overrides
$toolbar-min-height             : 65px // quasar fails with rem unit
$toolbar-padding                : 0
