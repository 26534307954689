@use "~booksprout-app/src/css/quasar.variables.sass" as *
@use "~booksprout-app/src/css/mixins/media.sass" as *
@use 'sass:map'
@use '~booksprout-app/src/css/functions/rem-fn' as *
@use '~booksprout-app/src/css/functions/line-height-fn' as *
@use '~booksprout-app/src/css/mixins/media' as media-query
@use 'functions/get-color' as *
@use 'mixins/generate-paragraphs' as *
@use 'mixins/generate-headings' as *
@use 'mixins/generate-colors' as *
@use 'variables/typography'
@use 'variables/media' as media
@use 'variables/border-radius' as *

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;1,400;1,700&display=swap')

// generate css custom properties
@include generate-colors()

body
  background-color: get-color('bs-w')
  color: get-color(bs-gm)
  font-family: 'Open Sans', sans-serif
  font-size: rem-fn(14px)
  line-height: line-height(22px, 14px)

a
  color: get-color(bs-b)
  font-weight: 400
  text-decoration: none

  &:hover,
  &:focus
    text-decoration: underline

button[role=link]
  appearance: none
  background-color: transparent
  border: none
  color: get-color(bs-g)
  cursor: pointer
  padding: 0

  &:hover,
  &:focus
    text-decoration: underline

strong
  font-weight: 700

// generate headings
@include generate-headings()

// generate paragraphs
@include generate-paragraphs()

.bs-website
  @include media-query.from(media.$md-min)
    display: grid
    grid-template-rows: auto 1fr auto

.sr-only
  position: absolute
  width: 1px
  height: 1px
  padding: 0
  margin: -1px
  overflow: hidden
  clip: rect(0, 0, 0, 0)
  white-space: nowrap
  border-width: 0

.bs-w-mobile-nav
  display: flex
  justify-content: center
  margin-block-end: rem-fn(30)

  a
    border-block-end: 0 solid get-color(bs-g)
    color: get-color(bs-gm)
    padding-block-end: rem-fn(10)
    transition: font-weight .2s linear, border-width .2s linear

    &:hover,
    &:focus,
    &.router-link-exact-active
      border-block-end-width: rem-fn(1)
      color: get-color(bs-b)
      font-weight: 700
      text-decoration: none

    & + a
      margin-inline-start: rem-fn(40)

.bs-w-article-content
  margin-inline: auto
  max-inline-size: 100%
  padding-block: rem-fn(80)
  padding-inline: rem-fn(30)

  @include media-query.from(media.$md-min)
    max-inline-size: rem-fn(media.$content-max-width)
    padding-block: rem-fn(160)

.bs-w-member-ellipse
  align-items: center
  block-size: rem-fn(560)
  display: flex
  justify-content: center
  position: relative

  .bs-w-m-e-one,
  .bs-w-m-e-two,
  .bs-w-m-e-three,
  .bs-w-m-e-four
    border: rem-fn(1) solid #ECECEC
    box-sizing: border-box
    border-radius: 50%
    position: relative

  [class^=bs-w-m-e-] > img
    border-radius: 50%
    position: absolute
    z-index: 1

    &.bs-w-m-e-avatar-1
      right: rem-fn(143)
      top: rem-fn(47)

    &.bs-w-m-e-avatar-2
      left: rem-fn(128)
      top: rem-fn(67)

    &.bs-w-m-e-avatar-3
      left: rem-fn(51)
      top: rem-fn(193)

    &.bs-w-m-e-avatar-4
      right: rem-fn(115)
      top: rem-fn(216)

    &.bs-w-m-e-avatar-6
      bottom: rem-fn(220)
      left: rem-fn(113)

    &.bs-w-m-e-avatar-7
      bottom: rem-fn(149)
      left: 0

    &.bs-w-m-e-avatar-10
      bottom: rem-fn(189)
      right: rem-fn(53)

    &.bs-w-m-e-avatar-11
      bottom: rem-fn(126)
      right: rem-fn(197)

    &.bs-w-m-e-avatar-12
      bottom: rem-fn(66)
      left: rem-fn(152)

    &.bs-w-m-e-avatar-13
      bottom: rem-fn(36)
      right: rem-fn(80)

  .bs-w-m-e-one
    block-size: rem-fn(560)
    inline-size: rem-fn(560)

  .bs-w-m-e-two
    block-size: rem-fn(422)
    inline-size: rem-fn(422)
    left: calc(50% - #{rem-fn(211)})
    top: calc(50% - #{rem-fn(211)})

  .bs-w-m-e-three
    block-size: rem-fn(282)
    inline-size: rem-fn(282)
    left: calc(50% - #{rem-fn(141)})
    top: calc(50% - #{rem-fn(141)})

  .bs-w-m-e-four
    align-items: center
    block-size: rem-fn(168)
    display: flex
    inline-size: rem-fn(168)
    justify-content: center
    top: calc(50% - #{rem-fn(84)})
    left: calc(50% - #{rem-fn(84)})


  &-small
    block-size: rem-fn(190)

    .bs-w-m-e-one
      block-size: rem-fn(190)
      inline-size: rem-fn(190)

    .bs-w-m-e-two
      block-size: rem-fn(146)
      inline-size: rem-fn(146)
      left: calc(50% - #{rem-fn(73)})
      top: calc(50% - #{rem-fn(73)})

    img:not(.q-icon)
      left: calc(50% - #{rem-fn(49)})
      top: calc(50% - #{rem-fn(49)})

.bs-w-chip
  border-radius: $border-radius-base
  font-size: rem-fn(12)
  font-weight: 700
  line-height: line-height(16, 12)
  padding-block: rem-fn(6)
  padding-inline: rem-fn(14)

// duplicating dialog styles
// instead of dragging everything from common app
.bs-modal
  .q-dialog__inner > *
    background-color: #fff
    box-shadow: $bs-drop-shadow-block
    padding: rem-fn(50)
    position: relative

  &__close
    color: $bs-green
    cursor: pointer
    font-size: rem-fn(15)
    position: absolute
    right: rem-fn(30)
    top: rem-fn(20)

    .q-btn__wrapper
      min-block-size: rem-fn(18)
      min-inline-size: rem-fn(18)
      padding: 0

@keyframes flip
  from
    transform: rotateY(180deg)

  to
    transform: rotateY(-180deg)

.bs-spinner
  align-items: center
  background-color: get-color(bs-g)
  border-radius: 50%
  display: flex
  justify-content: center
  padding: rem-fn(20)

  svg
    block-size: rem-fn(48)
    inline-size: rem-fn(48)
    color: get-color(bs-w)

    path
      animation: flip 3s linear infinite
      transform-origin: 50% 50%
