@use "~booksprout-app/src/css/quasar.variables.sass" as *
@use "~booksprout-app/src/css/mixins/media.sass" as *
@import '~booksprout-app/src/css/functions/rem-fn'

.no-results__container
  .page__heading
    span
      max-inline-size: rem-fn(300)
      text-align: center
      word-break: break-word

    strong
      color: $bs-medium-grey

  .ellipse
    height: 312px
    margin-top: 50px

    div
      border: 1px solid
      box-sizing: border-box
      border-radius: 50%
      position: relative

    .one
      width: 295px
      height: 295px
      position: relative
      border-color: rgba(#C5D7E7, .4)

    .two
      width: 243px
      height: 243px
      position: relative
      top: calc(50% - 121px)
      left: calc(50% - 121px)
      border-color: rgba(#C5D7E7, .6)


    .three
      width: 194px
      height: 194px
      position: relative
      top: calc(50% - 97px)
      left: calc(50% - 97px)
      border-color: rgba(#C5D7E7, .8)


    .four
      width: 140px
      height: 140px
      position: relative
      top: calc(50% - 70px)
      left: calc(50% - 70px)
      border-color: rgba(#C5D7E7, 1)

  .square
    width: 260px
    height: 120px
    margin: 0 auto
    position: relative
    display: flex
    justify-content: center
    margin-top: 50px

    .one,
    .two,
    .three
      position: absolute
      background: #FFFFFF
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15)
      border-radius: 6px

    .one
      width: 208px
      height: 79px
      top: 40px

    .two
      width: 228px
      height: 87px
      top: 20px

    .three
      width: 248px
      height: 93px
      top: 0
      display: flex
      justify-content: space-evenly
      align-items: center

      .round,
      .inner-round
        border-radius: 50%
        display: flex
        justify-content: center
        align-items: center
        background: #FFFFFF
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15)

      .round
        width: 55px
        height: 55px

        .inner-round
          width: 43px
          height: 43px
          background-color: $bs-light-blue-really
          font-size: 22px

      .lines
        .line-one
          width: 102px
          height: 2px
          background-color: $bs-light-blue
          margin-bottom: 7px

        .line-two
          width: 40px
          height: 2px
          background-color: $bs-mustard-yellow

