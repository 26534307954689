@use "sass:map"
@use '~booksprout-app/src/css/functions/rem-fn' as *
@use '~booksprout-app/src/css/functions/line-height-fn' as *
@use '~booksprout-app/src/css/mixins/media' as media-query
@use '../functions/get-color' as *
@use '../variables/typography'
@use '../variables/media'

@mixin generate-headings
  :is(h1, h2, h3)
    color: get-color(bs-b)
    font-style: normal
    font-weight: 400
    letter-spacing: 0
    margin-block-start: 0
    margin-block-end: rem-fn(30)

  :is(.strong)
    font-weight: 700

  h1, .h1
    font-size: rem-fn(map.get(typography.$h1-mobile, 'font-size'))
    line-height: line-height(map.get(typography.$h1-mobile, 'line-height'), map.get(typography.$h1-mobile, 'font-size'))

    @include media-query.from(media.$md-min)
      font-size: rem-fn(map.get(typography.$h1, 'font-size'))
      line-height: line-height(map.get(typography.$h1, 'line-height'), map.get(typography.$h1, 'font-size'))

  h2, .h2
    font-size: rem-fn(map.get(typography.$h2-mobile, 'font-size'))
    line-height: line-height(map.get(typography.$h2-mobile, 'line-height'), map.get(typography.$h2-mobile, 'font-size'))

    @include media-query.from(media.$md-min)
      font-size: rem-fn(map.get(typography.$h2, 'font-size'))
      line-height: line-height(map.get(typography.$h2, 'line-height'), map.get(typography.$h2, 'font-size'))

  h3, .h3
    font-size: rem-fn(map.get(typography.$h3-mobile, 'font-size'))
    line-height: line-height(map.get(typography.$h3-mobile, 'line-height'), map.get(typography.$h3-mobile, 'font-size'))

    @include media-query.from(media.$md-min)
      font-size: rem-fn(map.get(typography.$h3, 'font-size'))
      line-height: line-height(map.get(typography.$h3, 'line-height'), map.get(typography.$h3, 'font-size'))
